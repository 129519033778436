import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { graphql, useStaticQuery } from 'gatsby';
import * as Styled from './../styles';
import { getAccessToken } from '../../../../state/utils';
import LogoMini from './../../../../assets/images/ustore-mob.png';
import { triggerAccountModal, triggerPwaInstall } from '../../../../state/global-triggers/global-triggers.service';
import Language from '../../../ui/Language';
import { I18nextContext } from 'gatsby-plugin-react-i18next';

const MobileMenu = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiNavigationMenu_en: strapiNavigationMenu(locale: { eq: "en" }) {
        shop_menu
        contact_us_menu
        get_the_app
        signup_button_label
        login_button_label
        mobile_account_link_text
        mobile_cart_checkout_button_label
        mobile_cart_go_back
        mobile_cart_heading
        mobile_contact_link_text
        mobile_enter_postcode_placeholder
        mobile_faq_link_text
        mobile_home_link_text
        mobile_liquors_link_text
        mobile_privacy_link_text
        mobile_terms_link_text
        mobile_welcome_back_text
      }
      strapiNavigationMenu_zh: strapiNavigationMenu(locale: { eq: "zh" }) {
        shop_menu
        contact_us_menu
        get_the_app
        signup_button_label
        login_button_label
        mobile_account_link_text
        mobile_cart_checkout_button_label
        mobile_cart_go_back
        mobile_cart_heading
        mobile_contact_link_text
        mobile_enter_postcode_placeholder
        mobile_faq_link_text
        mobile_home_link_text
        mobile_liquors_link_text
        mobile_privacy_link_text
        mobile_terms_link_text
        mobile_welcome_back_text
      }
    }
  `);
  const translations = gql[`strapiNavigationMenu_${locale}`];
  const { language } = React.useContext(I18nextContext);

  return (
    <Popover>
      <div className="fixed inset-x-0 bottom-0 h-14 z-auto">
        <Popover.Button className="w-12 h-12 items-center justify-center bg-white rounded-full shadow-md border border-grayu-light p-2 inline-flex text-grayu-light">
          <span className="sr-only">Open menu</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </Popover.Button>
      </div>

      <Transition>
        <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={() => {}}>
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-y-0 left-0 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="-translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="-translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative w-screen max-w-md">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="flex gap-2 align-middle text-xl font-medium text-gray-900">
                        <img src={LogoMini} alt="Ubottle" />
                        <b className="pt-2.5">Ubottle</b>
                      </Dialog.Title>
                      <span className="text-brown-vthin text-xssemi tracking-wide font-normal">
                        {translations.mobile_welcome_back_text}
                      </span>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      <div className="absolute inset-0 px-4 sm:px-6">
                        <div className="h-full" aria-hidden="true">
                          <Styled.NavWrapLinks className="text-left">
                            <Styled.NavLinks to="/">
                              {translations.mobile_home_link_text}
                            </Styled.NavLinks>
                            <Styled.NavLinks to="/shop">
                              {translations.mobile_liquors_link_text}
                            </Styled.NavLinks>

                            {getAccessToken() && (
                              <>
                                <Styled.FakeLinks
                                  onClick={() => {
                                    triggerAccountModal(new Date().getTime());
                                  }}
                                >
                                  {translations.mobile_account_link_text}
                                </Styled.FakeLinks>
                              </>
                            )}
                            {!getAccessToken() && (
                              <>
                                <Styled.NavLinks to="/login">
                                  {translations.mobile_account_link_text}
                                </Styled.NavLinks>
                              </>
                            )}

                            <Styled.NavLinks to="/contact">
                              {translations.mobile_contact_link_text}
                            </Styled.NavLinks>

                            <hr className="mt-4"/>

                            <Styled.NavMiniLinks to="/privacy-policy">
                              {translations.mobile_privacy_link_text}
                            </Styled.NavMiniLinks>
                            <Styled.NavMiniLinks to="/terms-and-condition">
                              {translations.mobile_terms_link_text}
                            </Styled.NavMiniLinks>

                            <hr className="mt-4"/>
                            <div className="py-2">
                              <Language lang={language}/>
                            </div>

                          </Styled.NavWrapLinks>

                          <div className="absolute inset-x-0 bottom-0 text-center h-6">
                            <button
                              type="button"
                              className="rounded-full p-2 bg-black text-gray-300 hover:text-white"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Popover>
  );
};

MobileMenu.propTypes = {
  locale: PropTypes.string,
};

MobileMenu.defaultProps = {
  locale: 'en',
};

export default MobileMenu;
