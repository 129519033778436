import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeroBanner from '../components/HeroBanner';
import { graphql, useStaticQuery } from "gatsby";
import { I18nextContext } from "gatsby-plugin-react-i18next";
const IndexPage: React.FC = () => {
  const { language } = React.useContext(I18nextContext);
  const gql = useStaticQuery(graphql`
    query {
        strapiHomepage_en: strapiHomepage(locale: {eq: "en"}) {
          page_title
        }
        strapiHomepage_zh: strapiHomepage(locale: {eq: "zh"}) {
          page_title
        }
    }
  `);
  const translations = gql[`strapiHomepage_${language}`];

  return (
    <Layout page='home' locale={language}>
      <SEO title={translations.page_title} />
      <HeroBanner locale={language}/>
    </Layout>
  );
};

export default IndexPage;
