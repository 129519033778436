import React from 'react';
import { graphql, useStaticQuery } from "gatsby";

import Banner from 'components/ui/Banner';

const HeroBanner = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
        strapiHomepage_en: strapiHomepage(locale: {eq: "en"}) {
          banner_title
          banner_subtitle
          search_placeholder
          explore_text
          submit_search_label
          page_title
        }
        strapiHomepage_zh: strapiHomepage(locale: {eq: "zh"}) {
          banner_title
          banner_subtitle
          search_placeholder
          explore_text
          submit_search_label
          page_title
        }
    }
  `);

  const translations = gql[`strapiHomepage_${locale}`];

  return (
    <Banner
      banner_title={translations.banner_title}
      banner_subtitle={translations.banner_subtitle}
      explore_text={translations.explore_text}
      search_placeholder={translations.search_placeholder}
      submit_search_label={translations.submit_search_label}
      page={`home`}
      locale={locale}
    />
  );
};

export default HeroBanner;
