import styled from 'styled-components';
import tw from 'twin.macro';
import bgHome from "./bg-ustore.jpg"

export const Wrap = styled.section`
  background: #232323;
  background-image: url(${bgHome});
  background-position: 50% 27%;
  height: 86vh;
  ${tw`flex items-center justify-center bg-no-repeat`};
  h1{
    line-height: 103px;
    ${tw`text-center text-white font-bold text-xl10`};
    @media screen and (min-width: 1900px) {
      font-size: 5vw;
      line-height: 5vw;
    }
    @media screen and (min-width: 600px) and screen(max: 1024px){
      font-size: 72px;
      line-height: 72px;
    }

    @media (max-width: 768px) {
      font-size: 100px;
      line-height: 100%;
      letter-spacing: 0.01em;
    }
    @media (max-width: 640px) {
      font-size: 70px;
    }
    @media (max-width: 520px) {
      font-size: 55px;
    }
    @media (max-width: 375px) {
      font-size: 50px;
    }
    @media (max-width: 320px) {
      font-size: 40px;
    }
    @media (max-width: 280px) {
      font-size: 35px;
    }
  }
  h3{
    font-size: 23px;
    line-height: 26px;
    letter-spacing: 0.07em;
    margin: 34px 0 41px;
    ${tw`text-grayu-medium2 text-center antialiased`};
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.1em;
    }
    @media (max-width: 320px) {
      font-size: 14px;
      margin: 25px 0;
    }
  }
  @media screen and (min-width: 1900px) {
    background-position: 50% 100%;
    background-size: contain;
    height: 100vh;
  }
  @media (max-width: 1024px) {
    height: 100vh;
  }

  @media (max-width: 1920px) {
    background-position: 50% 100%;
  }
  @media (max-width: 1024px) {
    background-position: 50% 100%;
    background-size: 150%;
    height: 92vh;
  }
  @media (max-width: 768px) {
    height: 92vh;
  }
`;


export const WrapSearch = styled.div`
  position: relative;
  width: 446px;
  height: 70px;
  ${tw`mx-auto`};
  input{
    ${tw`text-xssemi focus:ring-opacity-0`};
    width: 446px;
    height: 70px;
    background: transparent;
    border: 3px solid #FED988;
    line-height: 110%;
    letter-spacing: 0.01em;
    padding: 28px;
    @media (max-width: 768px) {
      width: 100%;
      height: 56px;
      padding: 0 10px;
    }
  }
  button{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    position: absolute;
    height: 38px;
    right: 21px;
    top: 20px;
    background: #FFC635;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    @media (max-width: 768px) {
      width: auto;
      height: 38px;
      right: 60px;
      top: 13px;
    }
    @media (max-width: 520px) {
      right: 10px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 48px;
  }
  @media (max-width: 520px) {
    padding: 0;
    width: 85%;
  }
`;

export const Notes = styled.p`
  line-height: 130%;
  letter-spacing: 0.202047px;
  margin: 25px auto 0;
  ${tw`text-white text-base text-center`};
  a{
    color: #FFC635;
    ${tw`px-1 text-center`};
    :hover{
      text-decoration: underline;
      color: #FFC635;
    }
  }
  @media (max-width: 768px) {
    width: 212px;
    font-size: 16px;
    line-height: 130%;
    display: block;
    text-align: center;
  }
`;

export const Desktop = styled.div`
  ${tw`hidden sm:block`};
`;

export const Mobile = styled.div`
  ${tw`block relative w-full text-center`};
  display: none;
  @media (max-width: 990px) {
    display: block;
  }
`;
