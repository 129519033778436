import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Subject } from 'rxjs';
import { catchError, debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import AutoSuggest from 'react-autosuggest';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

import * as Styled from './styles';
import { searchPostcodes } from '../../../state/user/user.service';
import { setCheckoutPostcode } from "../../../state/checkout/checkout.service";
import MobileMenu from './../../Header/MainNavi/MobileMenu'

const Banner = ({ banner_title, banner_subtitle, explore_text, search_placeholder, submit_search_label, locale }) => {
  const { navigate } = useI18next();
  const [unMount$] = useState(new Subject());
  const [searchValue$] = useState(new Subject());
  const [searchValue, setSearchValue] = useState('');
  const [selectedPostcode, setSelectedPostcode] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [showMobileNav, setShowMobileNav] = useState(true);

  useEffect(() => {
    searchValue$
      .pipe(
        takeUntil(unMount$),
        debounceTime(300),
        switchMap((value) => searchPostcodes(value)),
        catchError(() => {
          alert('Postcode Search Failed');
        })
      )
      .subscribe((results) => {
        setSuggestions(results);
      });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$, searchValue$]);

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    searchValue$.next(value);
  }

  const onSuggestionSelected = (e, { suggestionValue }) => {
    setSelectedPostcode(suggestionValue)
  }

  const getSuggestionValue = (suggestion) => ({
    id: suggestion.id,
    name: suggestion.name,
    serviced: suggestion.serviced,
    delivery_fee: suggestion.delivery_fee,
    est_del_time: suggestion.est_del_time,
  })

  const onChange = (_, { newValue }) => {
    setShowMobileNav(!newValue);
    setSearchValue(newValue.name ? newValue.name : newValue);
  }

  return (
    <>
      <Styled.Wrap>

        <div className="containner mx-auto">
          <div className="grid grid-cols-1 gap-2">
            <div >
              <div className="flex">
                <div className="homeBanner flex-shrink w-full px-0 sm:px-12">
                  <h1>{ banner_title }</h1>
                  <h3 className='thin'>{ banner_subtitle }</h3>
                  <Styled.WrapSearch>
                    <AutoSuggest
                      suggestions={suggestions}
                      onSuggestionsClearRequested={onSuggestionsClearRequested}
                      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                      onSuggestionSelected={onSuggestionSelected}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={(suggestion) => <span>{ suggestion.name }</span>}
                      inputProps={{
                        placeholder: `${search_placeholder}`,
                        value: searchValue,
                        name: 'postcode',
                        className:'text-white focus:outline-none',
                        onChange: onChange,
                      } }
                      highlightFirstSuggestion={ true }
                    />
                    <button onClick={ async () => {
                      if (!!selectedPostcode?.serviced) {
                        setCheckoutPostcode(selectedPostcode)
                        await navigate('/shop/')
                      } else if(!!selectedPostcode) {
                        await navigate('/unserviced/?pdata=' + JSON.stringify(selectedPostcode));
                      }
                    } }>{ submit_search_label }</button>
                  </Styled.WrapSearch>
                  <Styled.Notes>
                    <Link to="/shop">{ explore_text }</Link>
                  </Styled.Notes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Styled.Wrap>

      <Styled.Mobile>
        {showMobileNav && <MobileMenu locale={locale}/>}
      </Styled.Mobile>

    </>
  );
};

Banner.propTypes = {
  banner_title: PropTypes.string,
  banner_subtitle: PropTypes.string,
  explore_text: PropTypes.string,
  search_placeholder: PropTypes.string,
  submit_search_label: PropTypes.string,
  page: PropTypes.string,
  locale: PropTypes.string,
};

Banner.defaultProps = {
  locale: 'en',
};


export default Banner;
